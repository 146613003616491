import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public globals: Globals;
  public language: any = null;
  public isChangePassword: any;
  public userId: any;
  public userPass: any;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  
  onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];
  }

  onShowAlert(type: any,msg: any){
      this.custom_alert_type = type;
      this.custom_alert_text = msg;
      this.custom_alert_show = true;
  }

  custom_alert_header: any = "Change Password";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";


  ngOnInit(): void {
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
      console.log(this.language);
    }, 2000);
    this.userId = this.comservice.getFromLocal('userId');
    this.userPass = {oldpass: '', newpass: '', confirmpass: ''};
  }

  changePassword() {
    let flg = true;
    const all_error_msg: any = [];
    if (this.userPass.oldpass.trim() === '') {
     
      all_error_msg.push(this.language.ENTER_CURRENT_PASSWORD);

      if(flg === true){
          flg = false;
      }
    } else if (this.userPass.newpass.trim() === '') {
     
      all_error_msg.push(this.language.ENTER_NEW_PASSWORD);

      if(flg === true){
          flg = false;
      }
    } else if (this.userPass.newpass.length < 6) {
      
      all_error_msg.push(this.language.ENTER_YOUR_PASSWORD_LENGTH);

      if(flg === true){
          flg = false;
      }
    } else if (this.userPass.confirmpass.trim() === '') {
     
      all_error_msg.push(this.language.ENTER_CONFIRM_PASSWORD);

      if(flg === true){
          flg = false;
      }
    } else if (this.userPass.confirmpass.trim() !== this.userPass.newpass.trim()) {
      
      all_error_msg.push(this.language.CONFIRM_PASSWORD_NOT_MATCHED);

      if(flg === true){
          flg = false;
      }
    } 

    if(flg == true){
      this.apiservice.setChangePass(this.userPass, this.userId).subscribe((response: any) => {
        if (response.status === false) {
         
          all_error_msg.push(this.language.CURRENT_PASSWORD_NOT_MATCHED_WITH_USER);
          this.onShowAlert('error',all_error_msg); 
          setTimeout(()=>{                           
            this.onCloseAlert(); 
    
          } , 4000);

          
        } else {
          
          all_error_msg.push(this.language.PASSWORD_SUCCESSFULLY_CHANGED);
          this.onShowAlert('success',all_error_msg); 
          setTimeout(()=>{                           
            this.onCloseAlert(); 
    
          } , 4000);

         
          this.ngOnInit();
        }
      });

    }else{
      this.onShowAlert('error',all_error_msg); 
    }
      
    
  }


  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
