import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public globals: Globals;
  public language: any = null;
  public signupuser: any = {name: '', last_name: '', email: '', pass: '', phone: '', terms: false };

  public userdetails: any;
  userId: any = 0;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    this.userdetails = this.comservice.getFromLocal('user-details');
     
    if (this.userdetails) {
      if (this.userdetails.id) {
        this.userId = this.userdetails.id;
      }
    }
  }

  proceed(){
    let cart = this.comservice.get('cartDetails');
    if(this.userId > 0){
      if(cart.length > 0){
        this.router.navigate(['/checkout']);
      }else{
        this.router.navigate(['/products']);
      }
    }else{
      if(cart.length > 0){
        this.router.navigate(['/checkout']);
      }else{
        this.router.navigate(['/products']);
      }
     
    }
  }


  onCloseAlert(){
      this.custom_alert_show = false;
      this.custom_alert_type = null;
      this.custom_alert_text = [];

  }

  onShowAlert(type: any,msg: any){
      this.custom_alert_type = type;
      this.custom_alert_text = msg;
      this.custom_alert_show = true;
  }

  
    custom_alert_header: any = "Sign Up";
    custom_alert_btnText: any = "Accept"; 
    custom_alert_text: any = []; 
    custom_alert_type: any = "error"; 
    custom_alert_show: any = false; 
    pressCloseOnOutsideClick: any = true;
    alertStyles:any = "";
    headerStyles:any = "";
    textStyles:any = "";
    buttonStyles:any = "";
  

  ngOnInit(): void {

    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);

  }

  onSignUpSubmit() {

    let flg = true;
    const all_error_msg: any = [];
       
    if (this.signupuser.name === '') {
           
      all_error_msg.push(this.language.ENTER_FIRST_NAME);

      if(flg === true){
          flg = false;
      }
    }
    if (this.signupuser.last_name === '') {
          
      all_error_msg.push(this.language.ENTER_YOUR_LAST_NAME);

      if(flg === true){
          flg = false;
      }
    }
    if (this.signupuser.email === '') {
           
      all_error_msg.push(this.language.ENTER_YOUR_EMAIL);

      if(flg === true){
          flg = false;
      }
    }else{
      if(!this.signupuser.email.match(/^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,5}$/)){
        all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);
  
        if(flg === true){
            flg = false;
        }
      }
    }
    /*let atpos;
    atpos = this.signupuser.email.indexOf('@');
    let dotpos;
    dotpos = this.signupuser.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.signupuser.email.length) {
           
      all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);

      if(flg === true){
          flg = false;
      }
    }*/

    
    if (this.signupuser.pass === '') {
           
      all_error_msg.push(this.language.ENTER_YOUR_PASSWORD);

      if(flg === true){
          flg = false;
      }
    }else{
      if (this.signupuser.pass.length < 6) {
            
        all_error_msg.push(this.language.ENTER_YOUR_PASSWORD_LENGTH);
  
        if(flg === true){
            flg = false;
        }
      }
    }


    if(this.signupuser.phone === ''){
      all_error_msg.push(this.language.ENTER_YOUR_MOBILE);

      if(flg === true){
          flg = false;
      }
    }else{
      if (this.signupuser.phone.length < 10) {
            
        all_error_msg.push("Please Enter 10 digit mobile number");
  
        if(flg === true){
            flg = false;
        }
      }
    }

    
    if (this.signupuser.terms === false) {
      
      all_error_msg.push(this.language.CHECK_PRIVACY_TERMS);

      if(flg === true){
          flg = false;
      }
    }

    if(flg == true){

      this.apiservice.setSignUp(this.signupuser).subscribe((res: any) => {
        if (res.status) {
          this.comservice.setService('user-details', res.result);
          this.comservice.saveInLocal('user-details', res.result);
          this.comservice.saveInLocal('userId', res.result.id);

          all_error_msg.push("Sign Up Successfully");
          this.onShowAlert("success",all_error_msg); 

          setTimeout(()=>{                           
            this.onCloseAlert(); 

            let prevroute: any;
            prevroute = this.comservice.getService('prevroute');
            if (prevroute === 'checkout') {
              this.dataSharingService.isUserLoggedIn.next(true);
              this.router.navigate(['/' + prevroute]);
            }else{
              this.router.navigate(['/login']);
            }

           } , 4000);

            //this.dataSharingService.isUserLoggedIn.next(true);
          
  
        } else {
         
          all_error_msg.push(res.message);
          this.onShowAlert('error',all_error_msg);
        }
      });

    }else{
      this.onShowAlert('error',all_error_msg); 
    }
    
  }

  numbertype(event:any){
    if (!/[0-9]/.test(event.key)) {event.preventDefault();}
  }

}
