<div class="new-pop">
  <h1>{{language.ORDER_ID}} # {{orderDetails.id}} <small class="order_status">({{orderDetails.statustext}})</small></h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>
  <div class="order-bdy">
    <ngx-simplebar [options]="options">
      
      <div class="sec-box">
        <div class="row ">
          <div class="col-md-6">
            <div class="mdl-dtls">
              <h4>{{language.FROM}}</h4>
              <h5>{{orderDetails.bname}}</h5>
              <p>{{orderDetails.baddress}}</p>
              <p>{{orderDetails.bmobile}}</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mdl-dtls">
              <h4>{{language.TO}}</h4>
              <h5>{{orderDetails.buyerdata.name}} {{orderDetails.buyerdata.last_name}}</h5>
              <p *ngIf="orderDetails.order_type == '1'">{{orderDetails.buyerdata.delievryaddress}}</p>
              <p *ngIf="orderDetails.order_type == '1'">{{orderDetails.buyerdata.specialaddress}}</p>
              <p>{{orderDetails.buyerdata.email}}</p>
              <p>{{orderDetails.buyerdata.cel}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="sec-box">
        <div class="row">
          <div class="col-md-6">
            <h4>{{language.ORDER_DATE_TIME}} :</h4>
            <p>{{orderDetails.datetime}}</p>
          </div>
          
        </div>
      </div>
     
      <div class="row">
        <div class="col-md-12">
          <div *ngFor="let dishdata of orderDetails.dishdata">
            <div class="add-item" *ngFor="let dish of dishdata.data">
              <h6>{{dish.quantity}} X {{dish.name}}
                
                <span class="price-flt-ryt">{{orderDetails.currency_symbol}}{{dish.total}}</span>
              </h6>
            </div>
          </div>
          <div class="price-cht">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="confirmation_tbl">

              <tr>
                <td colspan="2">{{language.ORDER_TEMPLATE_SUB_TOTAL}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.subtotal}}</strong>
                </td>
              </tr>
             
              <tr *ngIf="orderDetails.order_type == '1'">
                <td colspan="2">{{language.DELIEVRY_FEE}}</td>
                <td>
                  <strong
                    *ngIf="orderDetails.deliverycost > 0">{{orderDetails.currency_symbol}}{{orderDetails.deliverycost}}</strong>
                  <strong *ngIf="orderDetails.deliverycost == 0" class="delivery-free-text">{{language.FREE}}</strong>
                </td>
              </tr>
              <tr>
                <td colspan="2">{{language.ORDER_TEMPLATE_TAX}} ({{orderDetails.tax}}%)
                  <span *ngIf="orderDetails.taxtype == 0">{{language.TAX_NOT_INCLUDED}} </span>
                  <span *ngIf="orderDetails.taxtype == 1">{{language.TAX_INCLUDED}}</span>
                </td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.tax_price}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.servicefee_price > 0">
                <td colspan="2">{{language.SERVICE_FEE}} ({{orderDetails.servicefee}}%)</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.servicefee_price}}</strong>
                </td>
              </tr>
             
              <tr *ngIf="orderDetails.discount > 0" class="clr-red">
                <td colspan="2">{{language.ORDER_TEMPLATE_DISCOUNT}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.discount}}</strong>
                </td>
              </tr>
              <tr *ngIf="orderDetails.firstoffer > 0" class="clr-red">
                <td colspan="2">{{language.DISCOUNT_FOR_FIRST_ORDER}}</td>
                <td><strong>{{orderDetails.currency_symbol}}{{orderDetails.firstoffer}}</strong>
                </td>
              </tr>

              <tr >
                <td colspan="2" class="total_text">{{language.ORDER_TEMPLATE_TOTAL}}</td>
                <td class="total_price_text">
                  <strong>
                    {{orderDetails.currency_symbol}}{{orderDetails.total}}
                  </strong>
                </td>
              </tr>
              
            
            </table>
          </div>
        </div>
      </div>
    </ngx-simplebar>

  </div>

  <div class="flx-btn" *ngIf="cancelBtn">
    <button (click)="onCancelClick()" class="sbm-btn cmn-button cncl-btn">{{language.CANCEL_ORDER}}</button>
  </div>
</div>
