<!-- feature-area-start -->
<section class="feature-area mainfeature__bg">
    <div class="container">
    <div class="mainfeature__border pb-15">
        <div class="row row-cols-lg-4 row-cols-md-3 row-cols-2">
            <div class="col">
                <div class="mainfeature__item text-center mb-30">
                <div class="mainfeature__icon">
                    <img src="./assets/images/feature-icon-1.svg" alt="">
                </div>
                <div class="mainfeature__content">
                    <h4 class="mainfeature__title">Fast Delivery</h4>
                    
                </div>
                </div>
            </div>
            <div class="col">
                <div class="mainfeature__item text-center mb-30">
                <div class="mainfeature__icon">
                    <img src="./assets/images/feature-icon-2.svg" alt="">
                </div>
                <div class="mainfeature__content">
                    <h4 class="mainfeature__title">safe payment</h4>
                    
                </div>
                </div>
            </div>
            <div class="col">
                <div class="mainfeature__item text-center mb-30">
                <div class="mainfeature__icon">
                    <img src="./assets/images/feature-icon-3.svg" alt="">
                </div>
                <div class="mainfeature__content">
                    <h4 class="mainfeature__title">Online Discount</h4>
                    
                </div>
                </div>
            </div>
            <div class="col">
                <div class="mainfeature__item text-center mb-30">
                <div class="mainfeature__icon">
                    <img src="./assets/images/feature-icon-4.svg" alt="">
                </div>
                <div class="mainfeature__content">
                    <h4 class="mainfeature__title">Help Center</h4>
                    
                </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
<!-- feature-area-end -->