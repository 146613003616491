
    <!-- BEGIN: Page Banner Section -->
    <section class="pageBannerSection">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pageBannerContent text-center">
                        <h2>Forgot Password</h2>
                        <div class="pageBannerPath">
                            <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Forgot your password</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END: Page Banner Section -->

    <!-- END: Cart Page Section -->
    <section class="track-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-sm-12">
                    <div class="tptrack__product mb-40">
                        <div class="tptrack__content grey-bg">
                            <div class="tptrack__item d-flex mb-20">
                                <div class="tptrack__item-icon">
                                    <i class="fal fa-user-unlock"></i>
                                </div>
                                <div class="tptrack__item-content">
                                    <h4 class="tptrack__item-title">Forgot Your Password</h4>
                                    <p>Your personal data will be used to support your experience throughout this
                                        website, to manage access to your account.</p>
                                </div>
                            </div>
                            <form (ngSubmit)="onForgotSubmit()">
                                    <div class="tptrack__id mb-10">
                                            <span><i class="fal fa-envelope"></i></span>
                                            <input type="email" placeholder="Email address" autocomplete="off" [(ngModel)]="forgot.email" [ngModelOptions]="{standalone:true}">
                                        
                                    </div>

                                    <div class="tptrack__btn mt-25">
                                        <button class="tptrack__submition active"> Reset Password <i class="fal fa-long-arrow-right"></i></button>
                                    </div>
                           </form>

                            <div class="tptrack__link mt-25"> Already Have Account?  <a routerLink="/login">Login</a></div>


                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="tptrack__product mb-40">
                        <div class="tptrack__content grey-bg">
                            <div class="tptrack__item d-flex mb-20">
                                <div class="tptrack__item-icon">
                                    <i class="fal fa-lock"></i>
                                </div>
                                <div class="tptrack__item-content">
                                    <h4 class="tptrack__item-title">New Costomers</h4>
                                    <p>Proceed to checkout and you will have an opportunity to create an account at the
                                        end of one dose that not already exist for you.</p>
                                </div>
                            </div>

                            <div class="tptrack__btn">
                                <button class="tptrack__submition tpsign__reg">Continue as Guest <i class="fal fa-long-arrow-right"></i>
                                </button>
                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END: Cart Page Section -->

    <app-custom-alert *ngIf="custom_alert_show"
                [header]="custom_alert_header"
                [btnText]="custom_alert_btnText"
                [text]="custom_alert_text"
                [type]="custom_alert_type"
                [show]="custom_alert_show"
                (onClosePressFunction)="onCloseAlert()"
                [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
                [alertStyles]="alertStyles"
                [headerStyles]="headerStyles"
                [textStyles]="textStyles"
                [buttonStyles]="buttonStyles"
                ></app-custom-alert>

     <!-- feature-area-start -->
     <app-feature-area></app-feature-area>
     <!-- feature-area-end -->
