import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  public globals: Globals;
  public language: any = null;
  public businessDetails: any = null;

  public productDetails: any = [];

  options = { autoHide: true, scrollbarMinSize: 100 };
  public businessId: any;
  public categoryDetails: any;
  public MenuDetails: any = [];
  public userdetails: any;
  public cartDetails: any;
  public orderType: any;
  public CartDish: any = [];
  public CartSubTotal: any;
  public PendingMinimumFee: any;
  public PendingFreeDelivery = false;
  public PendingFreeDeliveryValue: any;
  public myClonedcategoryDetails: any;
  public myClonedcategory: any;
  public from_order: any;
  public indexcart: any;
  public catLength: Number = 0;
  public catLimit: Number = 0;
  public scroll = 0;
  public appType: any;
  public cartQuantity = 0;
  public stickyheight: any;

  public selectable = false;
  public removable = true;
  public dish: any;
  public dishTotal: any;
  public from_order_pos: any;

  userId: any = 0;

  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private route: ActivatedRoute
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    let cart = this.comservice.get('cartDetails');
    if(cart.length > 0){
      this.CartDish = cart;
      this.businessDetails = this.comservice.get('businessDetails');
      this.calCartTotal();
    }else{
      this.router.navigate(['/products']);
    }

      this.userdetails = this.comservice.getFromLocal('user-details');
     
      if (this.userdetails) {
        if (this.userdetails.id) {
          this.userId = this.userdetails.id;
        }
      }

    
  }

  proceed(){
    this.comservice.setService('prevroute', 'checkout');
    if(this.userId > 0){
      this.router.navigate(['/checkout']);
    }else{
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);


    let uid = -1;
      
      this.apiservice.getBusiness(this.globals.BUSINESS_SLUG, uid).subscribe((response: any) => {
            if (Object.keys(response).length === 0) {
              this.router.navigate(['/404']);
            } else {
              this.comservice.setService('businessID', response.id);

              let is_img;
              is_img = JSON.parse(response.is_img);
              if (is_img.is_img === 1) {
                response.logo = is_img.data.secure_url;
              } else {
                response.logo = './assets/images/business_logo.png';
              }
              let is_banner;
              is_banner = JSON.parse(response.is_banner);
              if (is_banner.is_img === 1) {
                response.banner = is_banner.data.secure_url;
              } else {
                response.banner = './assets/images/business_banner.png';
              }
              this.comservice.set('businessDetails', response);
              this.businessDetails = response;
        }
      });



  }

  addTocartDecrement(index: any, dishindex: any, dishdataindex: any) {
    this.CartDish = this.comservice.get('cartDetails');
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    if (this.CartDish[index].dish[dishindex].data[dishdataindex].quantity === 0) {
      this.CartDish[index].dish[dishindex].data.splice(dishdataindex, 1);
      if (this.CartDish[index].dish[dishindex].data.length === 0) {
        this.CartDish[index].dish.splice(dishindex, 1);
      }
      if (this.CartDish[index].dish.length === 0) {
        this.CartDish.splice(index, 1);
      }
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    } else {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
    this.calCartTotal();

    let cart = this.comservice.get('cartDetails');
     
    if(cart.length == 0){
      this.router.navigate(['/products']);
    }
  }

  removeFromCart(index: any, dishindex: any, dishdataindex: any){
    this.CartDish = this.comservice.get('cartDetails');
   
      this.CartDish[index].dish[dishindex].data.splice(dishdataindex, 1);
      if (this.CartDish[index].dish[dishindex].data.length === 0) {
        this.CartDish[index].dish.splice(dishindex, 1);
      }
      if (this.CartDish[index].dish.length === 0) {
        this.CartDish.splice(index, 1);
      }
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
      this.calCartTotal();

      let cart = this.comservice.get('cartDetails');
     
      if(cart.length == 0){
        this.router.navigate(['/products']);
      }
  }

  addTocartIncrement(index: any, dishindex:any, dishdataindex: any) {
    this.CartDish = this.comservice.get('cartDetails');
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity + 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity + 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    this.calCartTotal();
    this.comservice.set('cartDetails', this.CartDish);
    this.dataSharingService.isCartAdded.next(true);
  }

  calCartTotal() {
    this.CartSubTotal = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
          }
        }
      }
    }
    this.PendingMinimumFee = this.businessDetails.minimumfee;
    if (this.CartDish.length > 0) {
      if (this.CartDish[0].id === this.businessDetails.id) {
        if (this.PendingMinimumFee > 0) {
          if (this.CartDish.length === 0) {
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
          } else {
            if (this.CartDish[0].id === this.businessDetails.id) {
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
            }
          }
        }

        if (this.businessDetails.freedeliverystatus === '1') {
          if (parseFloat(this.CartSubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
            this.PendingFreeDelivery = true;
            this.PendingFreeDeliveryValue = 0.00;
          } else {
            this.PendingFreeDelivery = false;
            this.PendingFreeDeliveryValue = parseFloat(this.businessDetails.freedeliveryvalue) - parseFloat(this.CartSubTotal);
            this.PendingFreeDeliveryValue = parseFloat(this.PendingFreeDeliveryValue).toFixed(2);
          }
        }
      }
    }
  }

}
