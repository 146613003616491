
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>About Us</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>About</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- BEGIN: About Section -->
        <section class="aboutPageSection01">
            <div class="container">
                <div class="aboutPageSection_inner">
                    <div class="row">

                        <div class="col-xl-6">
                            <div class="aboutContent">
                                <h2 class="secTitle">Best Seller Bhandari Ltd</h2>
                                <p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen  book.unknown printer took a galley of type and  scrambled it to make a type specimen book. </p>
                                <p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an  simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen  book.unknown printer took a galley of type and  scrambled it to make a type specimen book. </p>
                                

                            </div>
                        </div>


                        <div class="col-xl-6">
                            <div class="aboutImage">
                                <img src="./assets/images/nebomart.jpg" alt="about">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- END: About Section -->


        

        <!-- App download -->
        <!--<section class="addbanner">
            <div class="container">
                <div class="downloadappin">
                    <h2>WE OFFER FIRST CLASS SHRIMPS</h2>
                    <p>We guarantee you the best seafood, delivered daily to our dock, prepared to your liking. Our focus is on serving the absolute freshest fish and seafood.</p>


                    <div class="sc_intro_buttons">
                        <a routerLink="products">SHOP NOW</a>
                    </div>
                </div>
            </div>
        </section>-->
        <!-- App download -->

        <!-- BEGIN: Testimonial Section -->
        <app-business-review></app-business-review>
        <!-- END: Testimonial Section -->



        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->