

        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>Gallery</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Gallery</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- Gallery Section -->
        <section class="gallery">
            <div class="container">
                <div class="row" *ngIf="gallery_list.length > 0">
                    <div class="col-lg-4 col-md-3 col-sm-12" *ngFor="let gall of gallery_list"><a href="{{gall}}" class="instagramPhoto image" data-fancybox="gallery"><img src="{{gall}}" alt="images"/></a></div>
                </div>
                <div class="row" *ngIf="gallery_list.length == 0 && gallery_loading == 1">
                    <div class="col-lg-12">
                        No Gallery Image Found
                    </div>
                </div>

                <ngx-skeleton-loader *ngIf="galleryLoading" count="3" appearance="circle"
                                [theme]="{
                                    width: '30%',
                                    'border-radius': '10px',
                                    height: '300px',
                                    'background-color': '#e3e3e3'
                                }"
                            ></ngx-skeleton-loader>

            </div>
        </section>
        <!-- Gallery Section -->




        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->