
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>Your Cart Details</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Cart</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- END: Cart Page Section -->
         <section class="cart-area pt-100 pb-80">
            <div class="container">
            <div class="row">
               <div class="col-12">
                     <form action="#">
                        <div class="cartbox">
                           <div class="cartboxth">
                               <div class="product-thumbnail">Images</div>
                               <div class="cart-product-name">Courses</div>
                               <div class="product-price">Unit Price</div>
                               <div class="product-quantity">Quantity</div>
                               <div class="product-subtotal">Total</div>
                               <div class="product-remove">Remove</div>
                           </div>

                           <div *ngFor="let cartdish of CartDish;let cartindex = index">
                              <div *ngFor="let cdish of cartdish['dish'];let dishindex = index">

                                    <div class="cartboxtd" *ngFor="let dish of cdish['data'];let dataindex = index">
                                       <div class="product-thumbnail">
                                         
                                          <a routerLink="/product-details/{{dish.id}}" *ngIf="dish.img != ''">
                                             <img src="{{dish.img}}" alt="">
                                          </a>
                                          <a routerLink="/product-details/{{dish.id}}" *ngIf="dish.img == ''">
                                             <img src="./assets/images/no_image.png" alt="">
                                          </a>
                                       </div>
                                       <div class="cart-product-name">
                                          <div>
                                             <a routerLink="/product-details/{{dish.id}}">{{dish.name}}</a>
                                          </div>
                                          <div>
                                             <p *ngFor="let set of dish.sets" style="color: #F46E16;">
                                                <span>+ </span>{{set.name}} - {{set.choiceText}}
                                              </p>
                                          </div>

                                          

                                       </div>
                                       <div class="product-price">
                                          <span class="amount">{{businessDetails.currency_symbol}}{{dish.price}}</span>
                                       </div>
                                       <div class="product-quantity">
                                             <span class="cart-minus" (click)="addTocartDecrement(cartindex,dishindex, dataindex)">-</span>
                                             <input class="cart-input" type="text" readonly value="{{dish.quantity}}">
                                             <span class="cart-plus" (click)="addTocartIncrement(cartindex,dishindex, dataindex)">+</span>
                                       </div>
                                       <div class="product-subtotal">
                                          <span class="amount">{{businessDetails.currency_symbol}}{{dish.total}}</span>
                                       </div>
                                       <div class="product-remove">
                                          <a href="javascript:void(0);" (click)="removeFromCart(cartindex,dishindex, dataindex)"><i class="fal fa-times"></i></a>
                                       </div>
                                    </div>
                                </div>
                             </div>       
                                 
                          
                        </div>
                        <div class="row">
                           <div class="col-12">
                                 <div class="coupon-all">
                                    
                                    <div class="coupon2">
                                       <a routerLink="/products"><button class="tp-btn " name="update_cart" >Continue Shopping</button></a>
                                    </div>
                                 </div>
                           </div>
                        </div>
                        <div class="row justify-content-end">
                           <div class="col-md-5 ">
                                 <div class="cart-page-total">
                                    <h2>Cart totals</h2>
                                    <ul class="mb-20">
                                       <li>Subtotal <span>{{businessDetails.currency_symbol}}{{CartSubTotal}}</span></li>
                                    </ul>
                                    <a href="javascript:void(0);" (click)="proceed();" class="tp-btn ">Proceed to Checkout</a>
                                 </div>
                           </div>
                        </div>
                     </form>
               </div>
            </div>
            </div>
         </section>
        <!-- END: Cart Page Section -->

         <!-- feature-area-start -->
         <app-feature-area></app-feature-area>
         <!-- feature-area-end -->
 