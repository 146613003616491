import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { DataSharingService } from '../data-share.service';
import { Globals } from '../globals';

export interface DialogData {
  order: any;
}

@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.css']
})
export class OrderdetailsComponent {
  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  public orderDetails: any;
  public language: any;
  public cancelBtn = false;

  constructor(
    public dialogRef: MatDialogRef < OrderdetailsComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.orderDetails = data.order;
    let dateb: any;
    let now: any;
    let startDate: any;
    dateb = new Date().toLocaleString('en-US', {
      timeZone: this.orderDetails.timezone_code
    });
    now = new Date(dateb);

    console.log(startDate);
    console.log(now);
    let diffTime;
    diffTime = ((startDate - now) / 1000) / 3600;
    //if (diffTime > this.orderDetails.cancellationtime && this.orderDetails.status === '0') {
      if (this.orderDetails.status === '0') {  
      this.cancelBtn = true;
    } else {
      this.cancelBtn = false;
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
  onCancelClick() {
    this.apiservice.cancelOrder(this.orderDetails.id).subscribe((resp: any) => {
      this.onNoClick();
      this.dataSharingService.iscancelledOrder.next(true);
    });
  }
}
