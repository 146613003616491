import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from './globals';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private headers: Headers = new Headers({'Content-Type' : 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  });
  public globals: Globals;

  constructor(
    private http: HttpClient,
    globals: Globals
  ) {
    this.globals = globals;
  }

  fetchUserByMD5(Id:any) {
    const parameters = { f: 'fetchusrbymd5', id: Id };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchusrbymd5;
    }));
  }
  UpdatePass(userId:any, pass:any) {
    const parameters = { f: 'updatepass', userId: userId, pass: pass};
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.updatepass;
    }));
  }

  getLogin(email: any, pass: any) {
    const parameters = { f: 'login', email: email, pass: pass, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.login;
    }));
  }

  getLoginCheck(id: any) {
    const parameters = { id: id };
    return this.http.get(Globals.API_ENDPOINT_LOGIN_CHECK, { params: parameters }).pipe(map((res: any) => {
      return res;
    }));
  }

  setSignUp(user:any) {
    const parameters = { f: 'register_pinekart', langId: this.globals.LANG_ID, name: user.name, last_name: user.last_name, email: user.email, pass: user.pass, phone: user.phone  };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.register;
    }));
  }
  getForgot(forgot:any) {
    const parameters = { f: 'forgot', langId: this.globals.LANG_ID, email: forgot.email};
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.forgot;
    }));
  }
  
  getProductCategory(bid: any) {
    const parameters = { f: 'getproductcategory', bid: bid, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproductcategory;
    }));
  }

  getPhotoByBusiness(id: any) {
    const parameters = { f: 'businessphoto', Id: id };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessphoto;
    }));
  }

  getReviewByBusiness(id: any, limit: any) {
    const parameters = { f: 'businessreview_new', Id: id, limit: limit };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessreview_new;
    }));
  }

  getReviewByBusiness_all(id: any) {
    const parameters = { f: 'businessreview_all', Id: id};

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessreview_all;
    }));
  }

  getfeaturedproductbybusiness(id: any) {
    const parameters = { f: 'getfeaturedproductbybusiness', bid: id, langId: this.globals.LANG_ID  };

    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getfeaturedproductbybusiness;
    }));
  }

  getnewproductbybusiness(id: any) {
    const parameters = { f: 'getnewproductbybusiness', bid: id, langId: this.globals.LANG_ID  };

    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getnewproductbybusiness;
    }));
  }

  getUser(userId:any) {
    const parameters = { f: 'userbyid', userId: userId, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.userbyid;
    }));
  }
  getUpdateUser(userDetails:any) {
    const parameters = { f: 'update', langId: this.globals.LANG_ID, userId: userDetails.id, name: userDetails.name, last_name: userDetails.last_name, email: userDetails.email, address: userDetails.address, cel: userDetails.cel, location: JSON.stringify(userDetails.location) };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.update;
    }));
  }
  setChangePass(userPass:any, userId:any) {
    const parameters = { f: 'changepassword', userId: userId, oldpass: userPass.oldpass, newpass: userPass.newpass };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.changepassword;
    }));
  }
  updateUserImage(file:any) {
    let head:any;
    head = {headers: this.headers};
    return this.http.post(Globals.API_ENDPOINT_UPLOAD, file, head).pipe(map((res: any) => {
      return res;
    }));
  }

  getOrderByUser(businessId:any, userId:any) {
    const parameters = { f: 'fetchorderbyuser', langId: this.globals.LANG_ID, userId: userId, bId: businessId };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchorderbyuser;
    }));
  }

  getBusiness(pathstr: any, uid: any) {
    const parameters = { f: 'getbusinessid', customurl: pathstr, langId: this.globals.LANG_ID, uid: uid };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessid;
    }));
  }
  getBusinessMenu(bid:any, ordertype:any, preorderdetails:any) {
    const parameters = { f: 'getbusinessmenu', bId: bid, langId: this.globals.LANG_ID, orderType: ordertype, preorderDetails: preorderdetails };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessmenu;
    }));
  }
  getProductByCategory(dishid:any, orderType:any) {
    const parameters = { f: 'getproductbycategory', langId: this.globals.LANG_ID,  dishId: dishid, type: orderType};
    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproductbycategory;
    }));
  }

  getbusinessmenu_category(bid:any) {
    const parameters = { f: 'getbusinessmenu_category', bId: bid, langId: this.globals.LANG_ID};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessmenu_category;
    }));
  }


  getproductbyId(id:any) {
    const parameters = { f: 'getproductbyId', id: id, langId: this.globals.LANG_ID};
    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproductbyId;
    }));
  }

  CreateRzpayOrder(data: any) {
    const parameters = { f: 'CreateRzpayOrder', amount: data.amount, currency: data.currency, RAZORPAY_KEY_ID: data.RAZORPAY_KEY_ID,RAZORPAY_KEY_SECRET: data.RAZORPAY_KEY_SECRET};
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.rzpay_orderid;
    }));
  }
  CreateRzpayId(data: any) {
    const parameters = { f: 'CreateRzpayId'};
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.rzpay_id;
    }));
  }

  setContactus(contactDetails: any) {
    const parameters = { f: 'contact_pinekart', langId: this.globals.LANG_ID, name: contactDetails.name, email: contactDetails.email, subject: contactDetails.subject, phone: contactDetails.phone, message: contactDetails.message };
    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.contact;
    }));
  }
  getAllPaymentMethod(bid:any) {
    const parameters = { f: 'checkpayment', langId: this.globals.LANG_ID, bid: bid };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.checkpayment;
    }));
  }

  getAllCurrecny() {
    const parameters = { f: 'allcurrency'};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.allcurrency;
    }));
  }

  cancelOrder(id:any) {
    const parameters = { f: 'cancelorder', orderId: id, langId: this.globals.LANG_ID};
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.cancelorder;
    }));
  }

  
  getbusinessmenuByCategory(bid:any, page:any, category_id:any, category_type:any, parPageItem: any, search_keyword: any) {
    const parameters = { f: 'getbusinessmenuByCategory', bId: bid, langId: this.globals.LANG_ID, page: page, category_id: category_id, category_type: category_type, parPageItem: parPageItem , search_keyword: search_keyword};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessmenuByCategory;
    }));
  }

  getbusinessmenuBySubCategory(bid:any, page:any, category_id:any, subcategory_id:any, parPageItem: any, search_keyword: any) {
    const parameters = { f: 'getbusinessmenuBySubCategory', bId: bid, langId: this.globals.LANG_ID, page: page, category_id: category_id, subcategory_id: subcategory_id, parPageItem: parPageItem, search_keyword: search_keyword};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessmenuBySubCategory;
    }));
  }

  getDiscountBannerbybusiness(id: any) {
    const parameters = { f: 'discount_banner', Id: id, langId: this.globals.LANG_ID};

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.discount_banner;
    }));
  }

}
