import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.scss']
})
export class CustomAlertComponent implements OnInit {

  @Input()type:any;
  @Input()show:any;
  @Input()onClosePress:any;
  @Input()text:any;
  @Input()header:any;
  @Input()btnText:any;

  @Output()onClosePressFunction:EventEmitter<any> = new EventEmitter();

  colorToUse: any;
  colorToUse_close: any;


  constructor() { 
  }

  onClosePressClick() {
    this.onClosePressFunction.emit();
  }

  ngOnInit(): void {
    
        if (this.type == "error") {
          this.colorToUse = "alert-button error_alert";
          this.colorToUse_close = "alert-cross-bt error_alert";
        }
        else if (this.type == "success"){
          this.colorToUse = "alert-button success_alert";
          this.colorToUse_close = "alert-cross-bt success_alert";
        } 
        else if (this.type == "warning"){
          this.colorToUse = "alert-button warning_alert";
          this.colorToUse_close = "alert-cross-bt warning_alert";
        }else{
          this.colorToUse = "alert-button defaultColor_alert";
          this.colorToUse_close = "alert-cross-bt defaultColor_alert";
        } 
}      

}
