<div class='alert-container' *ngIf="show">
   
      <div role='alert'>
        <div class='backdrop'></div>
        <div class='alert-main' >
          <h3 class='alert-header' >
            {{header}}

            <Button class={{colorToUse_close}} [ngClass]="'backgroundColor:' + colorToUse" (click)="onClosePressClick()" >X</Button>
          </h3>
          
          <ul className='alert-body' >
              <li *ngFor="let msg of text">{{msg}}</li>
           </ul>
         
            <Button class={{colorToUse}} (click)="onClosePressClick()" >{{btnText}}</Button>
                         
        </div>
      </div>
   
  </div>