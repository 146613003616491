
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>Contact</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Contact</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       <!-- END: Page Banner Section -->

        <!-- BEGIN: Contact Section -->
         <section class="map-area tpmap__box">
            <div class="container">
               <div class="row gx-0">
                  <div class="col-lg-6 col-md-6 order-2 order-md-1">
                     <div class="tpmap__wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d56215.718841453985!2d-0.19959027821222705!3d51.48739183082915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1slondon%20eye!5e0!3m2!1sen!2sbd!4v1656749326947!5m2!1sen!2sbd" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" width="600" height="450"></iframe>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-6 order-1 order-md-2">
                     <div class="tpform__wrapper pt-120 pb-80 ml-60">
                        <h4 class="tpform__title">LEAVE A REPLY</h4>
                        <p>Your email address will not be published. Required fields are marked *</p>
                        <div class="tpform__box">
                           <form action="#">
                              <div class="row gx-7">
                                 <div class="col-lg-6">
                                    <div class="tpform__input mb-20">
                                       <input type="text" placeholder="Your Name *" [(ngModel)]="contactDetails.name" [ngModelOptions]="{standalone: true}">
                                    </div>
                                 </div>
                                 <div class="col-lg-6">
                                    <div class="tpform__input mb-20">
                                       <input type="email" placeholder="Your Email *" [(ngModel)]="contactDetails.email" [ngModelOptions]="{standalone: true}">
                                    </div>
                                 </div>
                                 <div class="col-lg-6">
                                    <div class="tpform__input mb-20">
                                       <input type="text"  placeholder="Subject *" [(ngModel)]="contactDetails.subject" [ngModelOptions]="{standalone: true}">
                                    </div>
                                 </div>
                                 <div class="col-lg-6">
                                    <div class="tpform__input mb-20">
                                       <input type="text" placeholder="Phone" (keypress)="numberOnly($event)" [(ngModel)]="contactDetails.phone" [ngModelOptions]="{standalone: true}">
                                    </div>
                                 </div>
                                 <div class="col-lg-12">
                                    <div class="tpform__textarea">
                                       <textarea name="message" placeholder="Message" class="mb-25" [(ngModel)]="contactDetails.message" [ngModelOptions]="{standalone: true}"></textarea>
                                       
                                       <button (click)="send_message()">Send message</button>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        <!-- END Contact Section -->

        <app-custom-alert *ngIf="custom_alert_show"
        [header]="custom_alert_header"
        [btnText]="custom_alert_btnText"
        [text]="custom_alert_text"
        [type]="custom_alert_type"
        [show]="custom_alert_show"
        (onClosePressFunction)="onCloseAlert()"
        [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
        [alertStyles]="alertStyles"
        [headerStyles]="headerStyles"
        [textStyles]="textStyles"
        [buttonStyles]="buttonStyles"
        ></app-custom-alert>


        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->