import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';


@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  review_list: any = [];
  review_loading: any = 0;
  reviewLoading = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private comservice: CommonService,
    private apiservice: ApiService,
    private router: Router,
    private globals: Globals
  ) { }

  ngOnInit(): void {
      this.apiservice.getReviewByBusiness_all(this.globals.BUSINESS_ID).subscribe((response: any) => {
        this.review_list = response.data;
        this.review_loading = 1;
        this.reviewLoading = false;
    });
  }

}
