   
      
      
      <!-- BEGIN: Slider Section -->
        <!--<section class="sliderSection02">
            <div class="rev_slider_wrapper">
                <div id="rev_slider_2" class="rev_slider fullwidthabanner" style="display:none;" data-version="5.4.1">
                    <ul>

                        <li data-index="rs-3047" data-transition="random-premium" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="1000"  data-thumb=""  data-rotate="0"  data-saveperformance="off"  data-title="" data-param1="01" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <img src="./assets/images/4.jpg"  alt="Souq Slider"  data-bgposition="left center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="0" class="rev-slidebg" data-no-retina/>
                            <div class="container">
                                <div class="Scontent">
                                    <span>Valentin Paul Essential Collection</span>
                                </div>
                            </div>
                        </li>

                        <li data-index="rs-3048" data-transition="random-premium" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="1000"  data-thumb=""  data-rotate="0"  data-saveperformance="off"  data-title="" data-param1="01" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <img src="./assets/images/4.jpg"  alt="Souq Slider"  data-bgposition="left center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="0" class="rev-slidebg" data-no-retina/>
                            <div class="container">
                                <div class="Scontent">
                                    <span>Valentin Paul Essential Collection</span>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>-->
        <!-- END: Slider Section -->

        <section class="sliderSection02">
            <div class="rev_slider_wrapper">

                <owl-carousel-o class="homeslider" [options]="customOptions">
                    <ng-container >
                    <ng-template  carouselSlide [id]="'1'" >
                        <div class="home_slider_image">
                            <img src="./assets/images/4.jpg"  alt="Souq Slider"  />
                                <div class="container">
                                    <div class="Scontent">
                                        <span>Valentin Paul Essential Collection</span>
                                    </div>
                                </div>
                        </div>   
                    </ng-template>
                    </ng-container>
                    <ng-container >
                        <ng-template  carouselSlide [id]="'2'" >
                            <div class="home_slider_image">
                                <img src="./assets/images/4.jpg"  alt="Souq Slider"  />
                                    <div class="container">
                                        <div class="Scontent">
                                            <span>Valentin Paul Essential Collection</span>
                                        </div>
                                    </div>
                                </div>        
                        </ng-template>
                    </ng-container>
                    
                </owl-carousel-o>
            </div>
        </section>        
        
        <!-- BEGIN: Category Section -->
        <section class="categorySection2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    
                            <owl-carousel-o class="categoryCarousel2" [options]="customOptions2">
                                <ng-container class="categoryItem01 ci01Mode2 text-center" *ngFor="let cat of productCategory" >
                                  <ng-template  carouselSlide [id]="'1'" >
                                    <div class="ci01Thumb" >
                                        <img *ngIf="cat.img != ''" src="{{cat.img}}" alt="{{cat.name}}" (click)="gotoproduct(cat.id)"/>
                                        <img *ngIf="cat.img == ''" src="../../assets/images/no_image_cat.png" alt="" alt="{{cat.name}}" (click)="gotoproduct(cat.id)"/>
                                    </div>
                                    <h3 (click)="gotoproduct(cat.id)">{{cat.name}}</h3>
                                   </ng-template>
                                </ng-container>
                                
                            </owl-carousel-o>

                            <ngx-skeleton-loader *ngIf="productCategoryLoading" count="4" appearance="circle"
                                [theme]="{
                                    width: '22%',
                                    'border-radius': '10px',
                                    height: '157px',
                                    'background-color': '#e3e3e3'
                                }"
                            ></ngx-skeleton-loader>

                        
                    </div>
                </div>

            </div>
            
        </section>
        <!-- END: Category Section -->

       
         <!-- BEGIN: Category Section -->
         <section class="Popular-product" *ngIf="newproduct.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="secTitle">New Arrival</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">

                                
                        <owl-carousel-o class="categoryCarousel3" [options]="customOptions3">
                            <ng-container *ngFor="let new of newproduct">
                              <ng-template class="tpproduct p-relative" carouselSlide [id]="'1'">
                                <div class="tpproduct__thumb p-relative text-center" *ngIf="new.img == ''">
                                    <a routerLink="/product-details/{{new.id}}"><img src="./assets/images/no_image.png" alt=""></a>
                                 </div>
                                 <div class="tpproduct__thumb p-relative text-center" *ngIf="new.img != ''">
                                    <a routerLink="/product-details/{{new.id}}"><img src="{{new.img}}" alt=""></a>
                                 </div>
                                 <div class="tpproduct__content">
 
                                    <h4 class="tpproduct__title"><a routerLink="/product-details/{{new.id}}">{{new.name}}</a></h4>
 
                                    <span class="tpproduct__content-weight">
                                     <!--<p>{{new.description}}</p>-->
                                     <div *ngIf="new.description != ''" [innerHTML] = "new.description"></div>
                                     </span>

                                     <div style="width: 100%;">
                                        <div style="width: 70%; float: left;" class="tpproduct__price">
                                        <span>{{businessDetails.currency_symbol}} {{new.price}}</span>
                                        </div>

                                        <div class="cstmz-div">
                                            <span class="cstmz" *ngIf="new.sets.length > 0">{{language.CUSTOMIZABLE}}</span>
                                        </div>
                                     </div>   

                                 </div>
 
                                 <div class="cartbox">
                                     <button class="plusbtn" [ngClass]="cartDishArr.indexOf(new.id) > -1 ? 'hide_div' : 'show_div'"  (click)="openProduct(new.category, new)"
                                     *ngIf="businessDetails.open == true  && businessDetails.bclosed == false">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                         <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                             <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                             <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                         </g>
                                         </svg>
                                     </button>
 
                                     <div class="cartboxin" *ngIf="cartDishArr.indexOf(new.id) > -1" [ngClass]="cartDishArr.indexOf(new.id) > -1 ? 'show_div_flex' : 'hide_div'">
                                         <button class="cartminus" (click)="addTocartDecrement(new.category, new)">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
                                                 <g id="Rectangle_364" data-name="Rectangle 364" transform="translate(0 2) rotate(-90)" fill="#fff" stroke="#fff" stroke-width="1">
                                                   <rect width="2" height="12" stroke="none"/>
                                                   <rect x="0.5" y="0.5" width="1" height="11" fill="none"/>
                                                 </g>
                                               </svg>
                                               
                                         </button>
                                         <input type="text" class="cartvalue" readonly value="{{cartDishFullArr[cartDishArr.indexOf(new.id)].quantity}}">
                                         <button class="cartplus" (click)="openProduct(new.category, new)">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                 <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                                     <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                                     <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                                 </g>
                                                 </svg>
                                         </button>
                                     </div>
                                 </div>

                               </ng-template>
                            </ng-container>
                            
                        </owl-carousel-o>

                    </div>
                </div>
            </div>
        </section>
        <!-- END: Category Section -->



        <!-- BEGIN: Category Section -->
        <section class="whight-product" *ngIf="featuredproduct.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="secTitle">Featured  Product</h2>
                    </div>
                   
                </div>
                <div class="row">
                    <div class="col-lg-12">

                        
                        <owl-carousel-o class="categoryCarousel3" [options]="customOptions3">
                            <ng-container *ngFor="let featured of featuredproduct">
                              <ng-template class="tpproduct p-relative" carouselSlide [id]="'{{featured.id}}'">
                                <div class="tpproduct__thumb p-relative text-center" *ngIf="featured.img == ''">
                                    <a routerLink="/product-details/{{featured.id}}"><img src="./assets/images/no_image.png" alt=""></a>
                                 </div>
                                 <div class="tpproduct__thumb p-relative text-center" *ngIf="featured.img != ''">
                                    <a routerLink="/product-details/{{featured.id}}"><img src="{{featured.img}}" alt=""></a>
                                 </div>
                                 <div class="tpproduct__content">
 
                                    <h4 class="tpproduct__title"><a routerLink="/product-details/{{featured.id}}">{{featured.name}}</a></h4>
 
                                    <span class="tpproduct__content-weight">
                                     <!--<p>{{featured.description}}</p>-->
                                     <div *ngIf="featured.description != ''" [innerHTML] = "featured.description"></div>
                                     </span>

                                     <div style="width: 100%;">
                                        <div style="width: 70%; float: left;" class="tpproduct__price">
                                        <span>{{businessDetails.currency_symbol}} {{featured.price}}</span>
                                        </div>

                                        <div class="cstmz-div">
                                            <span class="cstmz" *ngIf="featured.sets.length > 0">{{language.CUSTOMIZABLE}}</span>
                                        </div>
                                     </div>   

                                 </div>
 
                                 <div class="cartbox">
                                     <button class="plusbtn" [ngClass]="cartDishArr.indexOf(featured.id) > -1 ? 'hide_div' : 'show_div'" href="javascript:void(0);" (click)="openProduct(featured.category, featured)"
                                     *ngIf="businessDetails.open == true  && businessDetails.bclosed == false">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                         <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                             <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                             <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                         </g>
                                         </svg>
                                     </button>
 
                                     <div class="cartboxin" *ngIf="cartDishArr.indexOf(featured.id) > -1" [ngClass]="cartDishArr.indexOf(featured.id) > -1 ? 'show_div_flex' : 'hide_div'">
                                         <button class="cartminus" (click)="addTocartDecrement(featured.category, featured)">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="2" viewBox="0 0 12 2">
                                                 <g id="Rectangle_364" data-name="Rectangle 364" transform="translate(0 2) rotate(-90)" fill="#fff" stroke="#fff" stroke-width="1">
                                                   <rect width="2" height="12" stroke="none"/>
                                                   <rect x="0.5" y="0.5" width="1" height="11" fill="none"/>
                                                 </g>
                                               </svg>
                                               
                                         </button>
                                         <input type="text" class="cartvalue" readonly value="{{cartDishFullArr[cartDishArr.indexOf(featured.id)].quantity}}">
                                         <button class="cartplus" (click)="openProduct(featured.category, featured)">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                 <g id="Union_1" data-name="Union 1" transform="translate(1764 326)" fill="#fff">
                                                     <path d="M -1757.499877929688 -314.5001220703125 L -1758.499633789062 -314.5001220703125 L -1758.499633789062 -318.9996032714844 L -1758.499633789062 -319.4996032714844 L -1758.999633789062 -319.4996032714844 L -1763.5 -319.4996032714844 L -1763.5 -320.5003051757812 L -1758.999633789062 -320.5003051757812 L -1758.499633789062 -320.5003051757812 L -1758.499633789062 -321.0003051757812 L -1758.499633789062 -325.4998168945312 L -1757.499877929688 -325.4998168945312 L -1757.499877929688 -321.0003051757812 L -1757.499877929688 -320.5003051757812 L -1756.999877929688 -320.5003051757812 L -1752.500366210938 -320.5003051757812 L -1752.500366210938 -319.4996032714844 L -1756.999877929688 -319.4996032714844 L -1757.499877929688 -319.4996032714844 L -1757.499877929688 -318.9996032714844 L -1757.499877929688 -314.5001220703125 Z" stroke="none"/>
                                                     <path d="M -1753.000366210938 -319.9996032714844 L -1753.000366210938 -320.0003051757812 L -1763 -319.9996032714844 L -1753.000366210938 -319.9996032714844 M -1756.999877929688 -314.0001220703125 L -1758.999633789062 -314.0001220703125 L -1758.999633789062 -318.9996032714844 L -1764 -318.9996032714844 L -1764 -321.0003051757812 L -1758.999633789062 -321.0003051757812 L -1758.999633789062 -325.9998168945312 L -1756.999877929688 -325.9998168945312 L -1756.999877929688 -321.0003051757812 L -1752.000366210938 -321.0003051757812 L -1752.000366210938 -318.9996032714844 L -1756.999877929688 -318.9996032714844 L -1756.999877929688 -314.0001220703125 Z" stroke="none" fill="#fff"/>
                                                 </g>
                                                 </svg>
                                         </button>
                                     </div>
 
                                 </div>
                               </ng-template>
                            </ng-container>
                            
                        </owl-carousel-o>
                       
                        
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Category Section -->

        <!-- BEGIN: Category Section -->
        <section class="whight-product" *ngIf="featuredproductLoading">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                            <ngx-skeleton-loader  count="4" appearance="circle"
                            [theme]="{
                                width: '24%',
                                'border-radius': '10px',
                                height: '300px',
                                'background-color': '#e3e3e3'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </section>                



        <!-- App download -->
        <!--<section class="downloadapp">
            <div class="container">
                <div class="downloadappin">
                    <h2>WE OFFER FIRST CLASS SHRIMPS</h2>
                    <p>We guarantee you the best seafood, delivered daily to our dock, prepared to your liking. Our focus is on serving the absolute freshest fish and seafood.</p>

                    <div class="sc_intro_buttons">
                        <a routerLink="products">SHOP NOW</a>
                    </div>
                </div>
            </div>
        </section>-->
        <!-- App download -->

        
        


       


        <!-- BEGIN: Category Section -->
        <section class="Popular-product" *ngIf="newproductLoading">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                            <ngx-skeleton-loader  count="4" appearance="circle"
                            [theme]="{
                                width: '24%',
                                'border-radius': '10px',
                                height: '300px',
                                'background-color': '#e3e3e3'
                            }"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
        </section>     



        <!-- add2-->
        <!--<section class="add2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="add2_in">
                            <span>GET SPECIAL</span>
                            <h2>Diamond tennis bracelets</h2>
                            <p>If you like the idea of the highest quality diamonds available for a price as stunning as the sparkle, meet your new best friend. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
        <!-- App download -->

        
        <!-- App download -->
       
            <!--*ngIf="discount_banner.length > 0" style="background-image: url({{banner.is_img}});"-->

            <div *ngIf="discount_banner.length > 0">

                <section  class="downloadapp"  *ngFor="let discount of discount_banner" style="background-image: url({{discount.is_img}});">
                    <div class="container"  >
                        <div class="row">

                        <div class="col-md-4"></div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <div class="offerdiv">

                                <div class="special_offer" *ngIf="discount.title != ''">{{discount.title}}</div>

                                <!-- <img src="../../assets/images/offerimg.png" alt=""> -->
                                <div class="valubox" *ngIf="discount.discount_percent != ''">{{discount.discount_percent}}</div>

                                <div class="offer_icon" *ngIf="discount.discount_percent != ''">%</div>
                                <div class="offer_text" *ngIf="discount.discount_percent != ''">off</div>

                                
                                <a class="shopbtn" routerLink="products">SHOP NOW</a>

                            </div>
                        </div>
                        </div>

                    </div>
                </section>
            </div>    

        
        <!-- App download -->



        <!-- BEGIN: galler Section -->
        <section class="gallerySection" *ngIf="gallery_list.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="secTitle">Our Gallery</h2>
                    </div>
                    <!-- <div class="col-md-6 text-end">
                        <a href="#" class="viewallbtn"><span>View All</span></a>
                    </div> -->
                </div>
                <div class="row">

                    <div class="col-lg-3" *ngFor="let gall of gallery_list">
                        <div class="galleryimage">
                            <a href="{{gall}}" data-fancybox="gallery"><img src="{{gall}}" alt="images"/></a>
                        </div>                                
                    </div>

                </div>
            </div>
        </section>
        <!-- END: Instagram Section -->

        <app-custom-alert *ngIf="custom_alert_show"
        [header]="custom_alert_header"
        [btnText]="custom_alert_btnText"
        [text]="custom_alert_text"
        [type]="custom_alert_type"
        [show]="custom_alert_show"
        (onClosePressFunction)="onCloseAlert()"
        [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
        [alertStyles]="alertStyles"
        [headerStyles]="headerStyles"
        [textStyles]="textStyles"
        [buttonStyles]="buttonStyles"
        ></app-custom-alert>

        <!-- BEGIN: Testimonial Section -->
        <app-business-review></app-business-review>
        <!-- END: Testimonial Section -->



        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->


