import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterMenu'
})
export class FilterMenuPipe implements PipeTransform {
    transform(items: any[], field : string, value : string): any[] {  
      if (!items) return [];
      if (!value || value.length == 0) return items;
      /*return items.filter(it => 
      it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);*/

       /*items.filter(it => it[field].indexOf(value) !=-1);

       return items;*/

       return items.filter(it => 
        it[field].indexOf(value) !=-1);
     
    }
}

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.name.toString().toLowerCase().includes(searchText);
        });
    }
}

@Pipe({
    name: 'filterorder'
})
export class OrderFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.id.toString().toLowerCase().includes(searchText);
        });
    }
}
