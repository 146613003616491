

        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="pageBannerContent text-center">
                            <h2>Testimonial</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Testimonial</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- BEGIN: TestimonialPage Section -->
        <section class="testimonialPage">
            <div class="container">
                                
                    <div class="row" *ngIf="review_list.length > 0">

                        <ngx-masonry [options]="{ gutter: 25, columnWidth: 3}">

                            <div ngxMasonryItem class="masonry_div" *ngFor="let review of review_list">
                                <div class="testimonialItem01">
                                    <div class="ti01Header clearfix">
                                        <i class="tc-quote"></i>
                                    </div>
                                    <div class="testiContent">
                                    {{review.comment}}
                                    </div>
                                    <div class="tiAuthor">
                                        <img *ngIf="review.img == ''" src="./assets/images/user.png" alt=""/>
                                        <img *ngIf="review.img != ''" src="{{review.img}}" alt=""/>
                                        <h3>{{review.name}}</h3>
                                    </div>
                                </div>
                                
                            </div>


                        </ngx-masonry>
                                           
                    </div>
                    <div class="row" *ngIf="review_list.length == 0 && review_loading == 1">
                        <div class="col-lg-12">
                            No Record Found
                        </div> 
                    
                    </div>

                    <ngx-skeleton-loader *ngIf="reviewLoading" count="4" appearance="circle"
                        [theme]="{
                            width: '24%',
                            'border-radius': '10px',
                            height: '300px',
                            'background-color': '#e3e3e3'
                        }"
                    ></ngx-skeleton-loader>
                  
            </div>
        </section>
        <!-- END: TestimonialPage Section -->



        <!-- feature-area-start -->
        <app-feature-area></app-feature-area>
        <!-- feature-area-end -->