
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>My Account</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>My Account</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->
        
        <!-- Account Start -->
        <section class="account_section">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="L_common_W">
                            <div class="usercol">
                                <div class="user_img">
                                    <img src="{{userdetails.img}}" alt="">

                                    <span><i class="fa fa-camera" aria-hidden="true"></i>
                                        <input type='file' (change)="onSelectFile($event)">
                                      </span>
                                    
                                </div>
                                
                                <h5>{{userdetails.name}} {{userdetails.last_name}}</h5>
                            </div>
                            
                            <div id="filter" class="innerfilter">
                                <ul>							
                                    <li><a [ngClass]="{'selected': selected_tab == 1 }" href="javascript:void(0);" (click)="changeTab(1);">My Account</a></li>
                                    <li><a  [ngClass]="{'selected': selected_tab == 2 }" href="javascript:void(0);" (click)="changeTab(2);">Canage Password</a></li>
                                    <li><a  [ngClass]="{'selected': selected_tab == 3 }" href="javascript:void(0);" (click)="changeTab(3);">Order History</a></li>
                                    <li><a  href="javascript:void(0);" (click)="goToLogout()">Logout</a></li>       
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <div class="R_common_W overflow-auto">
                            <div id="content">
                                
                                <!--tab1-->
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 psdbox" *ngIf="selected_tab == 1">
                                    <div class="Achd">ACCOUNT INFORMATION</div>
                                    <div class="row">
                                        <div class="tpform__box">
                                            <form (ngSubmit)="updateUser()">
                                               <div class="row gx-7">
                                                  <div class="col-lg-12">
                                                     <div class="tpform__input2 mb-20">
                                                        <input (keypress)="omit_special_char($event)" type="text" placeholder="First Name" autocomplete="off" [(ngModel)]="userdetails.name" [ngModelOptions]="{standalone:true}" >
                                                     </div>
                                                  </div>
                                                  <div class="col-lg-12">
                                                    <div class="tpform__input2 mb-20">
                                                       <input (keypress)="omit_special_char($event)" type="text" placeholder="Last Name" autocomplete="off" [(ngModel)]="userdetails.last_name" [ngModelOptions]="{standalone:true}" >
                                                    </div>
                                                 </div>
                                                 <div class="col-lg-12">
                                                    <div class="tpform__input2 mb-20">
                                                       <input type="text" #search autocomplete="off" placeholder="Address" (keyup)="PlaceChanged()"
                                                       autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="userdetails.address" [ngModelOptions]="{standalone:true}" >
                                                    </div>
                                                 </div>
                                                  <div class="col-lg-12">
                                                     <div class="tpform__input2 mb-20">
                                                        <input type="email" placeholder="Email" autocomplete="off" [(ngModel)]="userdetails.email" [ngModelOptions]="{standalone:true}">
                                                     </div>
                                                  </div>
                                                  
                                                  <div class="col-lg-12">
                                                     <div class="tpform__input2 mb-20">
                                                        <input type="text" maxlength="10" (keypress)="numbertype($event)" autocomplete="off" placeholder="Phone" [(ngModel)]="userdetails.cel" [ngModelOptions]="{standalone:true}">
                                                     </div>
                                                  </div>
                                                  <div class="col-lg-6">
                                                     <div class="tpform__textarea">
                                                        <button type="submit">Save Details</button>
                                                     </div>
                                                  </div>
                                               </div>
                                            </form>
                                         </div>
                                    </div>                                                    
                                </div>
                                <!--tab1 end-->

                                <ng-container #container></ng-container>

                            </div>
                        
                        </div>
                    </div>
                </div>
                
                
            </div>
        </section>
        <!-- Account End -->

        <app-custom-alert *ngIf="custom_alert_show"
                [header]="custom_alert_header"
                [btnText]="custom_alert_btnText"
                [text]="custom_alert_text"
                [type]="custom_alert_type"
                [show]="custom_alert_show"
                (onClosePressFunction)="onCloseAlert()"
                [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
                [alertStyles]="alertStyles"
                [headerStyles]="headerStyles"
                [textStyles]="textStyles"
                [buttonStyles]="buttonStyles"
                ></app-custom-alert>

        <!-- feature-area-start -->
    <app-feature-area></app-feature-area>
    <!-- feature-area-end -->