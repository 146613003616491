
        <!-- BEGIN: Page Banner Section -->
        <section class="pageBannerSection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="pageBannerContent text-center">
                            <h2>Checkout</h2>
                            <div class="pageBannerPath">
                                <a routerLink="/">Home</a>&nbsp;&nbsp; / &nbsp;&nbsp;<span>Checkout</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END: Page Banner Section -->

        <!-- BEGIN: Checkout Page Section -->
        <section class="coupon-area pt-70 pb-30" >
            <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="coupon-accordion" *ngIf="buyerDetails.id == 0">
                        <!-- ACCORDION START -->
                        <h3>Returning customer? <a routerLink="/login">Click here to login {{buyerDetails.id}}</a></h3>
                        <!-- ACCORDION END -->
                  </div>
               </div>
            </div>
         </div>
         </section>

        <!-- END: Checkout Page Section -->


        <!-- BEGIN: Checkout Page Section -->
         <!-- checkout-area start -->
         <section class="checkout-area pb-50">
            <div class="container">
               <form action="#">
                  <div class="row">
                        <div class="col-lg-6 col-md-12">
                           <div class="checkbox-form checkoutForm">
                              <h3>Your Billing Address</h3>
                              <div class="row">
                                    
                                    <div class="col-md-6" *ngIf="buyerDetails.id == 0">
                                       <div class="checkout-form-list">
                                          <label>First Name <span class="required">*</span></label>
                                          <input type="text" placeholder="First Name" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.name" [ngModelOptions]="{standalone: true}">
                                       </div>
                                    </div>

                                    <div class="col-md-6" *ngIf="buyerDetails.id == 0">
                                        <div class="checkout-form-list">
                                           <label>Last Name <span class="required">*</span></label>
                                           <input type="text" placeholder="Last Name" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.last_name" [ngModelOptions]="{standalone: true}">
                                        </div>
                                     </div>

                                    <div class="col-md-6">
                                        <div class="checkout-form-list">
                                           <label>Email Address <span class="required">*</span></label>
                                           <input type="email" placeholder="Email" (keypress)="omit_special_char($event)" [(ngModel)]="buyerDetails.email" [ngModelOptions]="{standalone: true}">
                                        </div>
                                     </div>

                                     <div class="col-md-6">
                                        <div class="checkout-form-list">
                                           <label>Mobile <span class="required">*</span></label>
                                           <input type="text" placeholder="Mobile" (keypress)="numberOnly($event)" [(ngModel)]="buyerDetails.cel" [ngModelOptions]="{standalone: true}">
                                        </div>
                                     </div>

                                     <div class="col-md-12">
                                        <div class="checkout-form-list">
                                           <label>Address <span class="required">*</span></label>
                                           <input type="text" placeholder="Delivery Address" [(ngModel)]="buyerDetails.delievryaddress" [ngModelOptions]="{standalone: true}">
                                        </div>
                                     </div>


                                     <!--<div class="col-lg-12">
                                        <div class="checkoutRegister create-acc ">
                                            <input type="checkbox" value="1" name="field11" id="is_register"/>
                                            <label for="is_register">Create Account?</label>
                                        </div>
                                    </div>-->


                              </div>
                           
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                           <div class="your-order mb-30 ">
                              <h3>Your order</h3>
                              <div class="your_order_table">
                                 <div class="order_row">
                                     <div class="">Product</div>
                                     <div class="">Total</div>
                                 </div>


                                 <div *ngFor="let cartdish of CartDish;let cartindex = index">
                                    <div *ngFor="let cdish of cartdish['dish'];let dishindex = index">
                              
                                       <div class="order_row" *ngFor="let dish of cdish['data'];let dataindex = index">
                                          <div class="">
                                             <span>{{dish.name}} <strong> × {{dish.quantity}}</strong></span>
                                          
                                             <p *ngFor="let set of dish.sets" style="color: #F46E16;">
                                                <span>+ </span>{{set.name}} - {{set.choiceText}}
                                              </p>
                                          </div>
                                          <div class=""><span class="amount"><sp *ngIf="businessDetails.currency_symbol">{{businessDetails.currency_symbol}}</sp>{{dish.total}}</span></div>
                                       </div>
                                    </div>
                                 </div>  
                                 
                                 <div class="cart_subtotal">
                                    <div><span *ngIf="language != null">{{language.ORDER_TEMPLATE_SUB_TOTAL}}</span></div>
                                    <div><span class="amount">{{businessDetails.currency_symbol}}{{SubTotal}}</span></div>
                                 </div>
                                 
                                 <div class="cart_subtotal" *ngIf="cartFee[0].taxprice > 0">
                                          <div><span *ngIf="language != null">{{language.ORDER_TEMPLATE_TAX}}({{cartFee[0].tax}}%)</span></div>
                                          <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].taxprice}}</span></div>
                                 </div>

                                 <div class="cart_subtotal" *ngIf="cartFee[0].servicefeeprice > 0">
                                    <div><span *ngIf="language != null">{{language.SERVICE_FEE}} ({{cartFee[0].servicefee}}%)</span></div>
                                    <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].servicefeeprice}}</span></div>
                                 </div>
                                 
                                 <div class="cart_subtotal">
                                       <div>Order Total</div>
                                       <div><span class="amount">{{businessDetails.currency_symbol}}{{cartFee[0].total}}</span></div>
                                 </div>      
                              
                                   
                              </div>

                              <ul class="wc_payment_methods">
                                
                                <li class="active" *ngFor="let pay of paymentMethod">
                                    <input type="radio"  value="{{pay.id}}" id="payment_{{pay.id}}" checked>
                                    <label for="payment_{{pay.id}}">{{pay.displayName}}</label>
                                </li>
                            </ul>

                            <div class="order-button-payment mt-20">
                                <button type="button" class="tp-btn tp-color-btn w-100 banner-animation" (click)="placeOrder()">Place order</button>
                             </div>


                           </div>
                        </div>
                  </div>
               </form>
            </div>
         </section>
         <!-- checkout-area end -->
        <!-- END: Checkout Page Section -->


        <app-custom-alert *ngIf="custom_alert_show"
                [header]="custom_alert_header"
                [btnText]="custom_alert_btnText"
                [text]="custom_alert_text"
                [type]="custom_alert_type"
                [show]="custom_alert_show"
                (onClosePressFunction)="onCloseAlert()"
                [pressCloseOnOutsideClick]="pressCloseOnOutsideClick"
                [alertStyles]="alertStyles"
                [headerStyles]="headerStyles"
                [textStyles]="textStyles"
                [buttonStyles]="buttonStyles"
                ></app-custom-alert>


          <!-- feature-area-start -->
          <app-feature-area></app-feature-area>
          <!-- feature-area-end -->
  