import { Component, OnInit, HostListener, } from '@angular/core';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var Razorpay: any;
declare var $: any;
declare let paypal: any;
declare let Stripe: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public globals: Globals;
  public language: any = null;

  public businessId: any;
  public businessDetails: any = null;
  public categoryDetails: any;
  public MenuDetails: any;
  public orderType: any;
  public CartDish: any;
  public SubTotal: any;
  public buyerDetails: any = {id: 0, name: '', last_name: '', email:'', cel: '', notes: '', specialaddress: ''}
  public discountInfo: any;
  public discount: any;
  public tips: any;
  public cartFee: any;
  public CartSubTotal: any;
  public txnId: any;
  public postData: any;
  public orderId: any;
  public businessDaysObject: any;
  public businessTime: any;
  minDate = new Date();
  maxDate = new Date();
  public currencyCode = 'INR';
  public currentTime: any;
  public addressReadonly = true;
  public deliveryDetails: any;
  public sourcetype: any;
  public sourcetypename: any;

  public Search: any;
public rewardsData: any;
public extraJson: any = {};

  

onCloseAlert(){
    this.custom_alert_show = false;
    this.custom_alert_type = null;
    this.custom_alert_text = [];

}

onShowAlert(type: any,msg: any){
    this.custom_alert_type = type;
    this.custom_alert_text = msg;
    this.custom_alert_show = true;
}


  custom_alert_header: any = "Checkout";
  custom_alert_btnText: any = "Accept"; 
  custom_alert_text: any = []; 
  custom_alert_type: any = "error"; 
  custom_alert_show: any = false; 
  pressCloseOnOutsideClick: any = true;
  alertStyles:any = "";
  headerStyles:any = "";
  textStyles:any = "";
  buttonStyles:any = "";

  RAZORPAY_KEY_ID:any;
  RAZORPAY_KEY_SECRET: any;


  public paymentId: any;
  public paymentMethod: any = [];


  constructor(
    private http: HttpClient,
    private comservice: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    public apiservice: ApiService,
    globals: Globals,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
  ) { 
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    let cart = this.comservice.get('cartDetails');
    if(cart.length > 0){
      this.CartDish = cart;
      this.businessDetails = this.comservice.get('businessDetails');

    }else{
      this.router.navigate(['/products']);
    }

    
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    
    setTimeout(() => {
      this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];  
    }, 1500);
    this.CartDish = this.comservice.get('cartDetails');
    
    this.orderType = 1;

    this.CalTotal();
    let date;
    date = new Date().toLocaleString('en-US', {
      timeZone: this.businessDetails.timezone_code
    });
    
    this.currentTime = new Date(date);
  
      this.apiservice.getAllPaymentMethod(this.businessDetails.id).subscribe((response: any) => {

        console.log(response.length);
        for (let j = 0; j < response.length; j++) {
                 
            let paymentc: any;
            paymentc = new Object();
            paymentc.id = response[j].pid;
            paymentc.name = response[j].name;
            paymentc.displayName = response[j].displayName;
            paymentc.active = false;
            paymentc.credential = response[j].credential;

            if(paymentc.id === '6'){

              this.paymentId = paymentc.id;
             
              this.RAZORPAY_KEY_ID = response[j].credential.id;
              this.RAZORPAY_KEY_SECRET = response[j].credential.secret;

              this.paymentMethod.push(paymentc);
            }
        }
      });
   

    let userDetails: any;
    userDetails = this.comservice.getFromLocal('user-details');

    if (userDetails === undefined || userDetails === null || Object.keys(userDetails).length === 0) {
      this.buyerDetails.id = 0;
      this.buyerDetails.name = '';
      this.buyerDetails.last_name = '';
      this.buyerDetails.email = '';
      this.buyerDetails.cel = '';
      this.buyerDetails.notes = '';
      this.buyerDetails.delievryaddress = '',
      this.buyerDetails.specialaddress = '';
    } else {
      this.buyerDetails.id = userDetails.id;
      this.buyerDetails.name = userDetails.name;
      this.buyerDetails.last_name = userDetails.last_name;
      this.buyerDetails.email = userDetails.email;
      this.buyerDetails.cel = userDetails.cel;
      this.buyerDetails.notes = '';
      this.buyerDetails.delievryaddress = userDetails.address,
      this.buyerDetails.specialaddress = userDetails.address;
    }

    this.sourcetype = 'web';
    this.sourcetypename = this.deviceService.getDeviceInfo();

    this.discount = 0.00;
    this.tips = 0.00;

    this.CartSubTotal = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
          }
        }
      }
    }


  }

  CalTotal() {
    let SubTotalDiscount: any;
    SubTotalDiscount = 0.00;
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            SubTotalDiscount = parseFloat(SubTotalDiscount) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            SubTotalDiscount = parseFloat(SubTotalDiscount).toFixed(2);
          }
        }
      }
    }
   
    this.CalDiscount();
     
  }
  CalDiscount() {
    this.cartFee = new Array();
    this.SubTotal = 0.00;
    this.discount = 0.00;

    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.SubTotal = parseFloat(this.SubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.SubTotal = parseFloat(this.SubTotal).toFixed(2);
          }
        }
      }
      let firstOffer: any;
      firstOffer = 0.00;
      /*if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.firstordervalue)) {
          firstOffer = parseFloat(this.SubTotal) * this.businessDetails.firstorderoffer * 0.01;
          firstOffer = parseFloat(firstOffer).toFixed(2);
        }
      }*/
      this.CartDish[i].details.delivery = 0.00;

      /*if (this.businessDetails.freedeliverystatus == 1) {
        if (parseFloat(this.SubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
          this.CartDish[i].details.delivery = 0.00;
        }
       }*/
     

      let extraminimum: any;
      extraminimum = 0.00;
     /* if (this.businessDetails.requiredminimumstatus === '1') {
        if (parseFloat(this.SubTotal) < parseFloat(this.businessDetails.minimumfee)) {
          extraminimum = parseFloat(this.businessDetails.minimumfee) - parseFloat(this.SubTotal);
          extraminimum = parseFloat(extraminimum).toFixed(2);
        }
      }*/

      let subtotal: any;
      subtotal = parseFloat(this.SubTotal) - parseFloat(this.discount);
      subtotal = parseFloat(subtotal).toFixed(2);

      let taxprice: any;
      taxprice = parseFloat(subtotal) * Number(this.CartDish[i].details.tax * 0.01);
      taxprice = parseFloat(taxprice).toFixed(2);

      let servicefeeprice: any;
      servicefeeprice = parseFloat(subtotal) + parseFloat(this.CartDish[i].details.delivery);
      servicefeeprice = servicefeeprice * this.CartDish[i].details.servicefee * 0.01;
      servicefeeprice = parseFloat(servicefeeprice).toFixed(2);

      let total: any;
      
      if (this.CartDish[i].details.taxtype == 0) {
        total = parseFloat(this.SubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(taxprice) + parseFloat(servicefeeprice);
        total = parseFloat(total).toFixed(2);

      } else {
        total = parseFloat(this.SubTotal) - parseFloat(this.discount) + parseFloat(this.CartDish[i].details.delivery) + parseFloat(servicefeeprice);
        total = parseFloat(total).toFixed(2);
      }


     /* if (this.businessDetails.requiredminimumstatus === '1') {
        total = parseFloat(total) + parseFloat(extraminimum);
        total = parseFloat(total).toFixed(2);
      }

      if (this.businessDetails.firstorderstatus === '1' && this.businessDetails.ordercountstatus) {
        total = parseFloat(total) - parseFloat(firstOffer);
        total = parseFloat(total).toFixed(2);
      }*/

      let fee: any;
      fee = {
        'id': this.CartDish[i].id,
        'name': this.CartDish[i].details.name,
        'subtotal': this.SubTotal,
        'tax': this.CartDish[i].details.tax,
        'taxtype': this.CartDish[i].details.taxtype,
        'taxprice': taxprice,
        'deliverycost': this.CartDish[i].details.delivery,
        'extraminimum': extraminimum,
        'firstoffer': firstOffer,
        'servicefee': this.CartDish[i].details.servicefee,
        'servicefeeprice': servicefeeprice,
        'discount': this.discount,
        'tips': 0,
        'total': total
      };
      
      this.cartFee.push(fee);

      let uDetails: any;
      uDetails = this.comservice.getFromLocal('user-details');
    }
  }

  placeOrder(){

    let flg = true;
    const all_error_msg: any = [];

    if (this.buyerDetails.name == undefined || this.buyerDetails.name == '') {
      
      all_error_msg.push(this.language.ENTER_FIRST_NAME);

      if(flg === true){
          flg = false;
      }
    }
    if (this.buyerDetails.last_name == undefined || this.buyerDetails.last_name == '') {
     
      all_error_msg.push(this.language.ENTER_YOUR_LAST_NAME);

      if(flg === true){
          flg = false;
      }
    }
    
    if (this.buyerDetails.delievryaddress == '' || this.buyerDetails.delievryaddress == undefined) {
      
      all_error_msg.push(this.language.ENTER_YOUR_ADDRESS);

      if(flg === true){
          flg = false;
      }
    }


    if (this.buyerDetails.email == '' || this.buyerDetails.email == undefined) {
     
       all_error_msg.push(this.language.ENTER_EMAIL_ADDRESS);

        if(flg === true){
            flg = false;
        }
    }
    let atpos;
    atpos = this.buyerDetails.email.indexOf('@');
    let dotpos;
    dotpos = this.buyerDetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.buyerDetails.email.length) {
      
        all_error_msg.push(this.language.ENTER_VALID_EMAIL_ADDRESS);

        if(flg === true){
            flg = false;
        }
    }

    if (this.buyerDetails.cel == '' || this.buyerDetails.cel == undefined) {
     
      all_error_msg.push(this.language.ENTER_YOUR_MOBILE);

        if(flg === true){
            flg = false;
        }
    }
     
    this.buyerDetails.preorder = false;
    this.buyerDetails.specialaddress = this.buyerDetails.delievryaddress; 

    if(flg == true){

      if(this.paymentId == 6){
        let jsondata = {
          "amount": this.cartFee[0].total,
          "currency": "INR",
          "RAZORPAY_KEY_ID": this.RAZORPAY_KEY_ID,
          "RAZORPAY_KEY_SECRET": this.RAZORPAY_KEY_SECRET,
        }

        this.apiservice.CreateRzpayOrder(jsondata).subscribe((response: any) => {
          if(response.success == true){
            this.payWithRazorpay(response.data.id);

          }else{
            all_error_msg.push(response.message);

            this.onShowAlert('error',all_error_msg);
            setTimeout(()=>{                           
              this.onCloseAlert(); 
      
             } , 4000);
          }
    
        });

      }else{
        all_error_msg.push("Please Select Payment Method");

        this.onShowAlert('error',all_error_msg);
      }

                   
    }else{
      this.onShowAlert('error',all_error_msg);
    }
  
  }

  payWithRazorpay(order_id : any){
    const options = {
      "key": this.RAZORPAY_KEY_ID,
      "amount": (this.cartFee[0].total * 100),
      "description": 'PineKart Order',
      "image":'',
      "order_id": order_id,//Order ID generated in Step 1
      "currency": 'INR',
      "handler": function (response: any) {
        var event = new CustomEvent("payment.success",
          {
            detail: response,
            bubbles: true,
            cancelable: true
          }
        );
        window.dispatchEvent(event);
      },
      "prefill": {
        "name": this.buyerDetails.name,
        "email": this.buyerDetails.email,
        "contact": this.buyerDetails.cel
      },
      "notes": {
        "address": this.buyerDetails.delievryaddress
      },
      "theme": {
        "color": "#3399cc"
      }
    };

    var rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response: any) {
      //this.message = "Payment Failed";
      // Todo - store this information in the server
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      //this.error = response.error.reason;
     
          alert("Payment failed please try again after some time");

      }
    );
       
  }
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {

    this.buyerDetails.name = this.buyerDetails.name.replace(/'/g, "");
    this.buyerDetails.name = this.buyerDetails.name.replace(/\"/g, "");

    this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/'/g, "");
    this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/\"/g, "");

    this.buyerDetails.notes = this.buyerDetails.notes.replace(/'/g, "");
    this.buyerDetails.notes = this.buyerDetails.notes.replace(/\"/g, "");


    /*this.postData = {
      f: 'placeorder_pinekart',
      langId: 1,
      userId: this.buyerDetails.id,
      CartDish: JSON.stringify(this.CartDish),
      cartFee: JSON.stringify(this.cartFee),
      BuyerDetails: JSON.stringify(this.buyerDetails),
      deliveryDetails: JSON.stringify(this.deliveryDetails),
      sourcetype: this.sourcetype,
      sourcetypename: JSON.stringify(this.sourcetypename),
      discountInfo: JSON.stringify(this.discountInfo),
      txnId: event.detail.razorpay_payment_id,
    };*/

    let Search;
    Search = {
      lat: '',
      lng: '',
      search_address: this.businessDetails.address,
      order_type: 1
    };
    
    this.Search = Search;
    
    this.postData = {
      f: 'placeorder_pinekart',
      langId: 1,
      userId: this.buyerDetails.id,
      CartDish: JSON.stringify(this.CartDish),
      cartFee: JSON.stringify(this.cartFee),
      BuyerDetails: JSON.stringify(this.buyerDetails),
      deliveryDetails: JSON.stringify(this.deliveryDetails),
      Search: JSON.stringify(this.Search),
      paymentMethod: JSON.stringify(this.paymentMethod),
      sourcetype: this.sourcetype,
      sourcetypename: JSON.stringify(this.sourcetypename),
      discountInfo: JSON.stringify(this.discountInfo),
      txnId: event.detail.razorpay_payment_id,
      extraJson: JSON.stringify(this.extraJson)
    };

    $.post(Globals.API_ENDPOINT_PLACE, this.postData, (res: any) => {
      this.orderId = res.placeorder_pinekart[0];
      this.comservice.set('orderId', this.orderId);
      
      this.router.navigate(['/confirmation']);
    });



  }



  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
  
}
