 <!-- BEGIN: Testimonial Section -->
 <section class="testimonialSection2" *ngIf="review_list.length > 0">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-12">
                <h2 class="secTitle" style="color:'#fff'; margin-bottom: 25px;">Testimonial</h2>
            </div>
        </div>    
        <div class="row">
            <div class="col-lg-12">
                <owl-carousel-o class="testimonialCarousel2" [options]="testimonialcustomOptions2">
                    <ng-container *ngFor="let review of review_list">
                      <ng-template  carouselSlide [id]="'{{review.id}}'">
                        <!--<div class="testimonialItem01">
                                <div class="ti01Author" *ngIf="review.img == ''">
                                    <img src="./assets/images/user.png" alt=""/>
                                </div>
                                <div class="ti01Author" *ngIf="review.img != ''">
                                    <img src="{{review.img}}" alt=""/>
                                </div>

                                <div class="ti01Content">
                                    {{review.comment}}
                                </div>
                                <div class="ti01Header clearfix">
                                    <h3>{{review.name}}</h3>
                                </div>
                            </div> -->  
                            
                            <div class="testimonialItem01 ti01Mode2">
                                <div class="ti01Header clearfix">
                                    <i class="tc-quote"></i>
                                </div>
                                <div class="ti01Content">
                                    {{review.comment}}
                                </div>
                                
                                <div class="ti01Author" *ngIf="review.img == ''">
                                    <img src="./assets/images/user.png" alt=""/>
                                    <h3>{{review.name}}</h3>
                                </div>
                                <div class="ti01Author" *ngIf="review.img != ''">
                                    <img src="{{review.img}}" alt=""/>
                                    <h3>{{review.name}}</h3>
                                </div>

                            </div>
                           
                       </ng-template>
                    </ng-container>

                    

                </owl-carousel-o>  
            </div>
        </div>
    </div>
</section>
<!-- END: Testimonial Section -->